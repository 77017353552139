var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { staticClass: "dialog", attrs: { value: _vm.value, width: "1200" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(
                  _vm._s(
                    "Copy - " +
                      _vm.itemCopy.orderCode +
                      " - " +
                      _vm.itemCopy.description
                  )
                )
              ]),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Item Code",
                          autocomplete: "off"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getByItemCode($event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", icon: "" } },
                                  [
                                    _c(
                                      "v-icon",
                                      { on: { click: _vm.getByItemCode } },
                                      [_vm._v("mdi-magnify")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.item.orderCode,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "orderCode", $$v)
                          },
                          expression: "item.orderCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          readonly: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Pack",
                          value: _vm.item.pack,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          readonly: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Size",
                          value: _vm.item.size,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          readonly: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "UPC",
                          value: _vm.item.UPC,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          readonly: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Item Description",
                          value: _vm.item.description,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "input",
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          placeholder: "0.00",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          prefix: "$",
                          label: "Case Cost",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "cost",
                              _vm.itemCopy.cost
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.cost,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "cost", $$v)
                          },
                          expression: "itemCopy.cost"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "AMAP",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "AMAP",
                              _vm.itemCopy.AMAP
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.AMAP,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "AMAP", $$v)
                          },
                          expression: "itemCopy.AMAP"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "Ad BB",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "adBB",
                              _vm.itemCopy.adBB
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.adBB,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "adBB", $$v)
                          },
                          expression: "itemCopy.adBB"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "Ad Scan",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "adScan",
                              _vm.itemCopy.adScan
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.adScan,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "adScan", $$v)
                          },
                          expression: "itemCopy.adScan"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "ePay Ad",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "ePay",
                              _vm.itemCopy.ePay
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.ePay,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "ePay", $$v)
                          },
                          expression: "itemCopy.ePay"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "EBA",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "EBA",
                              _vm.itemCopy.EBA
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.EBA,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "EBA", $$v)
                          },
                          expression: "itemCopy.EBA"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          max: "1",
                          outlined: "",
                          label: "Compete",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "compete",
                              _vm.itemCopy.Compete
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.compete,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "compete", $$v)
                          },
                          expression: "itemCopy.compete"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          placeholder: "0.00",
                          prefix: "$",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          outlined: "",
                          label: "PCAF",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "PCAF",
                              _vm.itemCopy.PCAF
                            )
                          }
                        },
                        model: {
                          value: _vm.itemCopy.PCAF,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "PCAF", $$v)
                          },
                          expression: "itemCopy.PCAF"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Deal ID",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.itemCopy.identifier,
                          callback: function($$v) {
                            _vm.$set(_vm.itemCopy, "identifier", $$v)
                          },
                          expression: "itemCopy.identifier"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { color: "#fafafa", flat: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  attrs: {
                    disabled: !_vm.item.UPC,
                    height: "40",
                    color: "#00b24a"
                  },
                  on: { click: _vm.copy }
                },
                [
                  _vm._v(" Add"),
                  _c("v-icon", { staticClass: "ml-1", attrs: { dense: "" } }, [
                    _vm._v("mdi-plus")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }