<template>
  <v-dialog :value="value" class="dialog" width="1200">
    <v-card color=#fafafa>
      <v-toolbar flat dark color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{`Copy - ${itemCopy.orderCode} - ${itemCopy.description}`}}</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined label="Item Code" v-model="item.orderCode"
              @keydown.enter="getByItemCode" autocomplete="off">
              <template v-slot:append>
                <v-btn small icon>
                  <v-icon @click="getByItemCode">mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field dense readonly background-color="#fff" outlined label="Pack" :value="item.pack"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense readonly background-color="#fff" outlined label="Size" :value="item.size"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense readonly background-color="#fff" outlined label="UPC" :value="item.UPC"
              autocomplete="off" />
          </v-col>
          <v-col cols="5">
            <v-text-field dense readonly background-color="#fff" outlined label="Item Description"
              :value="item.description" autocomplete="off" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field dense background-color="#fff" outlined class="input" placeholder="0.00" type="number"
              step="0.01" min="0" prefix="$" label="Case Cost" v-model="itemCopy.cost" @change="formatCurrencyInput('cost', itemCopy.cost)"
              autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="AMAP" v-model="itemCopy.AMAP" @change="formatCurrencyInput('AMAP', itemCopy.AMAP)"
              autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="Ad BB" v-model="itemCopy.adBB"
              @change="formatCurrencyInput('adBB', itemCopy.adBB)" autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="Ad Scan" v-model="itemCopy.adScan"
              @change="formatCurrencyInput('adScan', itemCopy.adScan)" autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="ePay Ad" v-model="itemCopy.ePay"
              @change="formatCurrencyInput('ePay', itemCopy.ePay)" autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="EBA" v-model="itemCopy.EBA" @change="formatCurrencyInput('EBA', itemCopy.EBA)"
              autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" max="1" outlined label="Compete" v-model="itemCopy.compete"
              @change="formatCurrencyInput('compete', itemCopy.Compete)" autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="PCAF" v-model="itemCopy.PCAF" @change="formatCurrencyInput('PCAF', itemCopy.PCAF)"
              autocomplete="off" />
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" outlined label="Deal ID" v-model="itemCopy.identifier"
              autocomplete="off" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar color="#fafafa" flat>
        <v-spacer></v-spacer>
        <v-btn :disabled="!item.UPC" height="40" color="#00b24a" class="white--text ml-2" @click="copy">
          Add<v-icon class="ml-1" dense>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'FeatureItemCopy',

  mixins: [notification],

  props: ['value', 'selectedItem', 'selectedFeature', 'getFeatureItems'],

  data () {
    return {
      item: {}
    }
  },

  computed: {
    itemCopy () {
      return cloneDeep(this.selectedItem)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.item = {}
          for (const key in this.itemCopy.item) {
            this.itemCopy[key] = this.itemCopy.item[key]
          }
          const valuesToFormat = {
            AMAP: this.itemCopy.AMAP,
            adBB: this.itemCopy.adBB,
            adScan: this.itemCopy.adScan,
            compete: this.itemCopy.compete,
            EBA: this.itemCopy.EBA,
            PCAF: this.itemCopy.PCAF,
            ePay: this.itemCopy.ePay,
            cost: this.itemCopy.cost
          }
          for (const key in valuesToFormat) {
            this.formatCurrencyInput(key, valuesToFormat[key])
          }
        }
      }
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      this.itemCopy[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    async getByItemCode () {
      await this.$contracts.getByItemCode(this.item.orderCode, this.selectedFeature.supplier.id)
        .then(response => {
          this.item = {
            orderCode: response[0].orderCode,
            itemCode: response[0].itemCode,
            pack: response[0].pack,
            size: response[0].size,
            UPC: response[0].UPC,
            description: response[0].description
          }
        }).catch(() => {
          this.notify('error', 'Item Not Available')
        })
    },

    async copy () {
      this.itemCopy.item = {
        orderCode: this.item.orderCode,
        itemCode: this.item.itemCode,
        pack: this.item.pack,
        size: this.item.size,
        UPC: this.item.UPC,
        description: this.item.description,
        cost: this.itemCopy.cost
      }
      await this.$ads.saveFeatureItem(this.itemCopy)
        .then(() => {
          this.notify('success', 'Item Successfully Added')
        }).catch((error) => {
          this.notify('error', error.response.data.error)
        })
      this.getFeatureItems(this.selectedFeature)
      this.item = {}
    }
  }
}
</script>
